import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  withStyles,
  IconButton,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import AbstractTable from "../components/AbstractTable";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Search from "@material-ui/icons/Search";
import { saveLocations, updateLocation } from "./locations-api";
import filterCollection from "../utils/filter-collection";
import { Loader } from "../components";

class LocationDialog extends PureComponent {
  static propTypes = {
    columns: PropTypes.array,
    current_tag: PropTypes.shape({
      id: PropTypes.number,
      current_location_alias_name: PropTypes.string,
      current_location_id: PropTypes.number,
      current_location_name: PropTypes.string,
      current_sensor_id: PropTypes.number,
      current_sensor_value: PropTypes.string,
      customer_id: PropTypes.number,
      label: PropTypes.string,
      tag_value: PropTypes.string
    }),
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      data: [],
      has_error: false,
      search_value: "",
      show_only_valid_sensors: true
    };
    this.saveLocations = saveLocations.bind(this);
    this.updateLocation = updateLocation.bind(this);
  }

  componentDidMount() {
    this.saveLocations(
      this.props.currentCustomer.id,
      this.props.current_tag.current_patient_department_visit_id
    );
  }

  updateTag = async item => {
    try {
      const response = await this.updateLocation(
        this.props.currentCustomer.id,
        this.props.current_tag.tag_value,
        item.sensor_value
      );
      if (response.status === 200) {
        await this.props.setTagToState(this.props.current_tag);
        this.props.handleSnackbarOpen();
        this.props.handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };
  columns = [
    {
      label: "Sensor value",
      property: "sensor_value",
      id: "sensor_value"
    },
    {
      label: "Location name",
      property: "location_name",
      id: "location_name",
      render: item => item["location_alias_name"] || item["location_name"]
    },
    {
      label: "Workflow name",
      property: "workflow_name",
      id: "workflow_name",
      render: item => item.workflow_name || "-"
    },
    {
      label: "Phase name",
      property: "phase_name",
      id: "phase_name",
      render: item => item.phase_name || "-"
    },
    {
      label: "Action",
      id: "action",
      isNotSortable: true,
      render: item => {
        return (
          <IconButton
            onClick={() => this.updateTag(item)}
            color="primary"
            className={this.props.classes.button}
            aria-label="Add an alarm"
          >
            <PlayArrow />
          </IconButton>
        );
      }
    }
  ];

  changeInputValue = e => {
    this.setState({ search_value: e.target.value });
  };

  showOnlyValidSensors = () => {
    this.setState(prevState => ({
      ...prevState,
      show_only_valid_sensors: !prevState.show_only_valid_sensors
    }));
  };

  render() {
    const { open, handleClose, classes } = this.props;
    const { search_value, data, show_only_valid_sensors, pending } = this.state;
    const keys = ["sensor_value", "location_name", "location_alias_name"];
    const filteredData = show_only_valid_sensors
      ? data.filter(({ is_valid }) => is_valid)
      : data;
    const searched_data = filterCollection(filteredData, keys, search_value);
    return (
      <div style={{ justifyContent: "center", alignItems: "center" }}>
        <Dialog
          maxWidth="xl"
          className={classes.dialog}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Locations"}</DialogTitle>
          <DialogContent>
            <div className={classes.wrapper}>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                onChange={this.changeInputValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={show_only_valid_sensors}
                    onChange={this.showOnlyValidSensors}
                    value={"show_only_valid_sensors"}
                    color="primary"
                  />
                }
                label="Show only valid sensors"
              />
            </div>
            {pending ? (
              <Loader containerStyles={{ width: 824 }} />
            ) : (
              <AbstractTable columns={this.columns} data={searched_data} />
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  table: {
    maxWidth: 1000,
    minWidth: 600,
    maxHeight: 300
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start"
  }
});

export default withStyles(styles)(LocationDialog);
