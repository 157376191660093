import React, { PureComponent } from "react";
import Auth from "./Auth";

export default class AuthenticationWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.auth = new Auth(
      props.config.REACT_APP_AUTH_DOMAIN,
      props.config.REACT_APP_AUTH_CLIENT_ID,
      props.config.REACT_APP_AUTH_CONNECTION,
      props.config.REACT_APP_AUTH_LOGIN_CALLBACK_PATH
    );
  }
  render() {
    const { children } = this.props;
    const { isAuthenticated, login } = this.auth;
    if (!isAuthenticated()) {
      return <React.Fragment>{login()}</React.Fragment>;
    }
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { auth:this.auth })
    );
    return <React.Fragment>{childrenWithProps}</React.Fragment>;
  }
}
