import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2
    },
    main: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 600,
    }
});

function Loader(props) {
  return (
    <div className={props.classes.main} style={{...props.containerStyles}}>
      <CircularProgress className={props.classes.progress} />
    </div>
  );
}



export default withStyles(styles)(Loader);
