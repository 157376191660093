import axios from "axios";

export async function setCustomersToState() {
  try {
    this.setState(prevState => ({ ...prevState, pending: true }));
    const Authorization = sessionStorage.getItem("access_token");
    const { data: availableCustomers } = await axios.get(
      `${this.props.config.REACT_APP_API_URL}/customers/available`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        }
      }
    );
    const { data: currentCustomer } = await axios.get(
      `${this.props.config.REACT_APP_API_URL}/customers/current`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        }
      }
    );
    if (availableCustomers) {
      this.setState(prevState => ({
        ...prevState,
        customers: availableCustomers,
        currentCustomer: currentCustomer
      }));
    }
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e;
  } finally {
    this.setState(prevState => ({ ...prevState, pending: false }));
  }
}
