export default function filterCollection(collection, keys, search_value) {
  return collection.filter(item => {
    for (let key of keys) {
      if (
        item[key] &&
        item[key]
          .toLowerCase()
          .indexOf(search_value && search_value.toLowerCase()) !== -1
      ) {
        return true;
      }
    }
    return false;
  });
}
