import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const styles = {
  root: {
    flexGrow: 1
  },
  toolbar: {
    justifyContent: "space-between",
    flexGrow: 1
  },
  button: {
    backgroundColor: "#fff"
  },
  formControl: {
    marginLeft: 20
  },
  customSelect: {
    color: "#fff"
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

export function MainAppBarComponent(props) {
  const { classes, auth, customers } = props;
  const isAuthenticated = auth.isAuthenticated();
  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar className={classes.toolbar}>
          <div className={classes.wrapper}>
            <Typography variant="h6" color="inherit">
              Simulator
            </Typography>

            <FormControl className={classes.formControl}>
              <Select
                style={styles.customSelect}
                value={props.currentCustomer.id}
                onChange={props.changeCustomer}
              >
                {customers.map((item, index) => (
                  <MenuItem key={item.id || index} value={item.id}>
                    {item.alias_name || item.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {isAuthenticated && (
            <Button className={classes.button} onClick={auth.logout}>
              Log out
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const MainBar = React.memo(MainAppBarComponent);

MainBar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired
  }).isRequired
};

export default withStyles(styles)(MainBar);
