import auth0 from "auth0-js";
import history from "../app/history";
import moment from "moment";

export default class Auth {
  constructor(
    domain,
    clientID,
    connection,
    callbackPath,
    responseType = 'token id_token',
    scope = 'openid'
  ) {
    this.login = this.login.bind(this, connection);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);

    const {protocol, hostname, port} = document.location;
    this.url = `${protocol}//${hostname + (port ? ":" + port : "")}`;
    this.auth0 = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri: `${this.url}/${callbackPath}`,
      responseType,
      scope
    });
  }
  login(connection) {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        console.log(err);
        return this.auth0.authorize({connection});
      }
      this.setSession(authResult);
    })
  }
  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.push("/");
      } else if (err) {
        history.push("/");
        console.log(err);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    sessionStorage.setItem("access_token", authResult.accessToken);
    sessionStorage.setItem("id_token", authResult.idToken);
    sessionStorage.setItem("expires_at", expiresAt);
    history.push('/');
  }

  logout() {
    // Clear Access Token and ID Token from local stora
    this.auth0.logout({
      returnTo: `${this.url}`,
      federated: true
    });
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("expires_at");
    // navigate to the home rou
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(sessionStorage.getItem("expires_at"));
    return moment().toDate() < expiresAt;
  }
}
