import React,{PureComponent} from "react";
import Loader from "../components/Loader";
import Auth from "./Auth";

export default class CallBack extends PureComponent {
    constructor(props){
        super(props);
        this.auth=new Auth(
            props.config.REACT_APP_AUTH_DOMAIN,
            props.config.REACT_APP_AUTH_CLIENT_ID,
            props.config.REACT_APP_AUTH_CONNECTION,
            props.config.REACT_APP_AUTH_LOGIN_CALLBACK_PATH);
    }

    componentDidMount() {
        this.handleAuthentication(this.props);
    }
    handleAuthentication = nextState => {
        if (/access_token|id_token|error/.test(nextState.location.hash)) {
            this.auth.handleAuthentication();
        }
    };
    render() {
        return <Loader {...this.props} />;
    }
}

