import axios from "axios";

export async function saveLocations(customerId, patientId) {
  try {
    const url = patientId
      ? `${
          this.props.config.REACT_APP_API_URL
        }/sensors/by-patient/${patientId}`
      : `${this.props.config.REACT_APP_API_URL}/sensors/`;
    const Authorization = sessionStorage.getItem("access_token");
    const response = await axios.get(url, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Authorization}`
      }
    });
    if (response) {
      this.setState(prevState => ({ ...prevState, data: response.data }));
    }
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e
  } finally {
    this.setState(prevState => ({ ...prevState, pending: false }));
  }
}

export async function updateLocation(customerId, tag_value, sensor_value) {
  try {
    const Authorization = sessionStorage.getItem("access_token");
    return await axios.put(
      `${
        this.props.config.REACT_APP_API_URL
      }/tags/${tag_value}/set-sensor/${sensor_value}`,
      {},
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        }
      }
    );
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e
  }
}
